h1 {
  font-weight: 500;
}

h2 {
  color: $accent-color;
}

h3 {
  font-weight: 450;
}

h4 {
  font-weight: 500;
}

h5 {
  font-style: normal;
  font-weight: 500;
}

ul,
ol {
  line-height: 2.2em;

  li {
    line-height: 2.2em;
  }
}
