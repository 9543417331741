.image-slider.animated {
  @keyframes slide-in-left {
    // Hier kann man leider keine Transition benutzen, da die Slide aus dem display none kommt.
    from {
      opacity: 0;
      transform: translateX(-20vw);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes slide-in-right {
    // Hier kann man leider keine Transition benutzen, da die Slide aus dem display none kommt.
    from {
      opacity: 0;
      transform: translateX(20vw);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .slider-content {
    opacity: 0;
  }

  .active .slider-content {
    animation-direction: normal;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;

    &.text-left {
      animation-name: slide-in-left;
    }

    &.text-right {
      animation-name: slide-in-right;
    }

    &:not(.text-left):not(.text-right) {
      opacity: 1;
    }
  }
}
