@font-face {
    font-family: 'Inter';
    src: url('/group/static/Inter/Inter-Bold.eot');
    src: local('Inter Bold'), local('Inter-Bold'),
    url('/group/static/Inter/Inter-Bold.eot?#iefix') format('embedded-opentype'),
    url('/group/static/Inter/Inter-Bold.woff2') format('woff2'),
    url('/group/static/Inter/Inter-Bold.woff') format('woff'),
    url('/group/static/Inter/Inter-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/group/static/Inter/Inter-ExtraBold.eot');
    src: local('Inter ExtraBold'), local('Inter-ExtraBold'),
    url('/group/static/Inter/Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('/group/static/Inter/Inter-ExtraBold.woff2') format('woff2'),
    url('/group/static/Inter/Inter-ExtraBold.woff') format('woff'),
    url('/group/static/Inter/Inter-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/group/static/Inter/Inter-Black.eot');
    src: local('Inter Black'), local('Inter-Black'),
    url('/group/static/Inter/Inter-Black.eot?#iefix') format('embedded-opentype'),
    url('/group/static/Inter/Inter-Black.woff2') format('woff2'),
    url('/group/static/Inter/Inter-Black.woff') format('woff'),
    url('/group/static/Inter/Inter-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/group/static/Inter/Inter-ExtraLight.eot');
    src: local('Inter ExtraLight'), local('Inter-ExtraLight'),
    url('/group/static/Inter/Inter-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('/group/static/Inter/Inter-ExtraLight.woff2') format('woff2'),
    url('/group/static/Inter/Inter-ExtraLight.woff') format('woff'),
    url('/group/static/Inter/Inter-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/group/static/Inter/Inter-Light.eot');
    src: local('Inter Light'), local('Inter-Light'),
    url('/group/static/Inter/Inter-Light.eot?#iefix') format('embedded-opentype'),
    url('/group/static/Inter/Inter-Light.woff2') format('woff2'),
    url('/group/static/Inter/Inter-Light.woff') format('woff'),
    url('/group/static/Inter/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/group/static/Inter/Inter-Medium.eot');
    src: local('Inter Medium'), local('Inter-Medium'),
    url('/group/static/Inter/Inter-Medium.eot?#iefix') format('embedded-opentype'),
    url('/group/static/Inter/Inter-Medium.woff2') format('woff2'),
    url('/group/static/Inter/Inter-Medium.woff') format('woff'),
    url('/group/static/Inter/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/group/static/Inter/Inter-Regular.eot');
    src: local('Inter Regular'), local('Inter-Regular'),
    url('/group/static/Inter/Inter-Regular.eot?#iefix') format('embedded-opentype'),
    url('/group/static/Inter/Inter-Regular.woff2') format('woff2'),
    url('/group/static/Inter/Inter-Regular.woff') format('woff'),
    url('/group/static/Inter/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/group/static/Inter/Inter-SemiBold.eot');
    src: local('Inter SemiBold'), local('Inter-SemiBold'),
    url('/group/static/Inter/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/group/static/Inter/Inter-SemiBold.woff2') format('woff2'),
    url('/group/static/Inter/Inter-SemiBold.woff') format('woff'),
    url('/group/static/Inter/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/group/static/Inter/Inter-Thin.eot');
    src: local('Inter Thin'), local('Inter-Thin'),
    url('/group/static/Inter/Inter-Thin.eot?#iefix') format('embedded-opentype'),
    url('/group/static/Inter/Inter-Thin.woff2') format('woff2'),
    url('/group/static/Inter/Inter-Thin.woff') format('woff'),
    url('/group/static/Inter/Inter-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}